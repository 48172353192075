module.exports = {
    login: {
        title_text: "Добро пожаловать в систему занятости!",
        center: "Центр",
        select_file: "Выберите ЭЦП!",
        password: "Пароль",
        login: "Войти",
    },

    center: {
        AnnouncementList: {
            ads: "Объявления",
            add_ads: "Добавить объявление",
            edit_ads: "Редактировать новость",
            add_news: "Добавить новость",
            save_news: "Сохранить",
            delete_news: "Удалить",
            edit_news: "Редактировать",
            look_news: "Посмотреть",
            alert_delet: "Вы действительно хотите удалить эту запись?",
        },

        ApplicantList: {
            list_applicant: "Претенденты",
            add_applicant: "Добавить соискателя",
            edit_applicant: "Редактировать соискателя",
            history: "История собеседования",
            oc: "Очередь",
            check: "Проверка",
            vseCategory: "Все категории"
        },

        CompanyAdmins: {
            edit_admin: "Редактировать администраторa",
            add_admin: "Добавить администраторa",
            admin_list: "Список администраторов компании",
            excel_export: "Экспортировать в Excel",
            close_tab: "Закрыть",
            downoload_file: 'Скачать',
            wait_until_load: 'Подождите пока файл загрузиться',
            finish_load: 'Файл готов к скачиванию'
        },

        ApplicantView: {
            archive_applicant: "Архивировать соискателя",
            cause: "Причина",
            comments: "Комментарий",
            rules: "Обязательное поле",
            attach_file: "Прекрепите файл",
            actions: "Совершил(а) действие",
            edit_request: "Изменил(а) статус заявки на",
            image: "Выберите картинку",
            archive: "Архивировать",
        },

        CenterLayoutView: {
            passages: "Вакансии",
            new: "Новые",
            vse: "Все вакансии",
            received: "Одобренные",
            published: "Опубликованные",
            completed: "Завершенные",
            rejected: "Отклоненные",
            unemployed: "В поиске работы",
            processing: "В обработке",
            interview: "Назначено собеседование",
            employed: "Трудоустроенные",
            archived: "Архивированные",
            settings: "Настроики",
            company_lists: "Список компании",
            administrators: "Администраторы",
            privileges: "Льготы",
            positions: "Позиции",
            ads: "Объявления",
            profile: "Профиль",
            all: "Все заявители",
            new_applicants: "Новые заявки",
            accepted_applicants: "Принятые",
            rejected_applicants: "Отклоненные"
        },

        CompanyView: {
            add_admin: "Добавить администратора",
            edit_admin: "Редактировать администратора",
            save: "Сохранить",
            statistics: "Статистика",
            applications_published: "Опубликовано заявок",
            applications_completed: "Завершено заявок",
            places_announced: "Обявлено мест",
            hired: "Принято на работу",
            positions: "Позиции",
            declared_places: "Опубликованные вакансии",
            excel_export: "Экспортировать в Excel",
            all_time: "За все время",
            per_week: "За неделю",
            per_month: "За месяц",
            per_year: "За год",
            all: "Все",
        },

        //Zhasik comp osigan dein

        CompanyList: {
            list_comnpany: "Список компании",
            add_company: "Добавить компанию",
            edit_company: "Редактировать компанию",
        },

        DashboardView: {
            vacancies: "Свободные места",
            sent: "Отправленные",
            published: "Опубликованные",
            finished: "Завершенные",
        },

        ProfileView: {
            profile: "Профиль",
            enterprise_data: "Данные предприятия",
            bin: "БИН:",
            company_name: "Medianas",
            user_date: "Данные пользователя",
        },

        ReferenceList: {
            edit_company: "Редактировать компанию",
            add: "Добавить",
            save: "Сохранить",
            directory_not_found: "Справочник не найден",
            privileges: "Льготы",
            positions: "Позиции",
        },

        VacancyList: {
            vacancies: "Свободные места",
            edit_company: "Редактировать компанию",
            add_free_space: "Добавить cвободное место",
            save: "Сохранить",
            export_to_excel: "Экспортировать в Excel",
            you_sure_delete: "Вы действительно хотите удалить эту запись?",
        },

        VacancyView: {
            declared_place: "Обьявленное место",
            submit_employment_center: "Отправить в центр занятости",
            sign_send: "Подписать и отправить",
            edit_app: "Редактировать заявку",
            delete_app: "Удалить заявку",
            accept_app: "Принять заявку",
            accept: "Принять",
            reject: "Отклонить",
            app: "заявку",
            reject_app: "Отклонить заявку",
            specify_reject_reason: "Укажите причину отказа",
            required_field: "Обязательное поле",
            value_short: "Значение слишком короткое",
            sign_and: "Подписать и",
            create_send_list_app: "Сформировать и отправить список кандидатов",
            create: "Сформировать",
            candidates: "кандидатов",
            add_beneficiaries: "Добавить льготников",
            specify_quantity: "Укажите количество",
            wrong_format: "Неправильный формат",
            value_high: "Значение слишком велико",
            add_vacancy: "Добавить",
            create_list_app: "Сформировать список кандидатов",
            send_list_app: "Отправить список кандидатов",
            all: "Все",
            info_candidate: "Информация о кандидате",
            changed_status_app: "Изменил(а) статус заявки на ",
            comments: "Комментарии",
            you_sure_delete: "Вы действительно хотите удалить эту запись?",
        },

        CompanyAdminsView: {
            list_admin_company: "Список администраторов компании",
            edit_admin: "Редактировать администраторa",
            add_admin: "Добавить администраторa",
            save_company: "Сохранить",
            export_to_excel: "Экспортировать в Excel",
        },
    },
    company: {
        AnnouncementList: {
            add_ads: "Добавить объявление",
            edit_ads: "Редактировать новость",
            add_news: "Добавить новость",
            save_news: "Сохранить",
            delete_news: "Удалить",
            edit_news: "Редактировать",
            look_news: "Посмотреть",
            you_sure_delete: "Вы действительно хотите удалить эту запись?",
        },
        AnnouncementView: {
            ads: "Объявления",
            add_ads: "Добавить объявление",
            delete_news: "Удалить",
            look_news: "Посмотреть",
        },
        ApplicantList: {
            recruited: "Принятые на работу",
            interviewing: "Проходит собеседование",
            export_to_excel: "Экспортировать в Excel",
        },
        CompanyLayoutView: {
            vacancies: "Свободные места",
            sign_out: "Выход",
            draft: "Черновик",
            sent: "Отправленные",
            approved: "Одобренные",
            published: "Опубликованные",
            finished: "Завершенные",
            declined: "Отклоненные",
            about_processing: "О обработке",
            interview_scheduled: "Назначено собеседование",
            employed: "Трудоустроенные",
            settings: "Настроики",
            ads: "Объявления",
            profile: "Профиль",
            in_kazakh: "Қазақша",
            in_rus: "Русский"
        },
        DashboardView: {
            vacancies: "Свободные места",
            sent: "Отправленные",
            published: "Опубликованные",
            finished: "Завершенные",
            pending_interview: "Ожидающие собеседования",
            kuanysh_fio: "Османов Қуаныш Рүстемұлы",
            profession: "Техник-технолог",
        },
        ProfileView: {
            profile: "Профиль",
            enterprise_data: "Данные предприятия",
            bin: "БИН:",
            company_name: "Medianas",
        },
        VacancyList: {
            add_free_space: "Добавить cвободное место",
            edit_company: "Редактировать компанию",
            save_company: "Сохранить",
            export_to_excel: "Экспортировать в Excel",
            you_sure_delete: "Вы действительно хотите удалить эту запись?",
        },
        VacancyView: {
            declared_place: "Обьявленное место",
            submit_employment_center: "Отправить в центр занятости",
            sign_send: "Подписать и отправить",
            edit_app: "Редактировать заявку",
            edit_company: "Редактировать компанию",
            add_free_space: "Добавить cвободное место",
            save_company: "Сохранить",
            delete_vacancy: "Удалить заявку",
            all: " Все",
            candidate_info: "Информация о кандидате",
            changed_status_app: "Изменил(а) статус заявки на ",
            comments: "Комментарии",
            schedule_interview: "Назначить собеседование",
            reject: "Отклонить",
            recruit: "Принять на работу",
            interview_date: "Дата собеседования",
            required_field: "Обязательное поле",
            interview_time: "Время собеседования",
            comments_address: "Комментарий / адрес",
            value_short: "Значение слишком короткое",
            send_vacancy: "Отправить",
            order_date: "Дата приказа",
            order_number: "Номер приказа",
            state_reason: "Укажите причину",
            you_sure_delete: "Вы действительно хотите удалить эту запись?",
        },
    },
    download: "Скачать"
};
