module.exports = {
    login: {
        title_text: "Жұмыспен қамту жүйесіне қош келдіңіз!",
        center: "Орталық",
        select_file: "ЭЦП таңдаңыз!",
        password: "Құпия сөз",
        login: "Жүйеге кіру",
    },

    center: {
        AnnouncementList: {
            ads: "Хабарландыру",
            add_ads: "Хабарландыру қосу",
            edit_ads: "Жаңалықтарды өңдеу",
            add_news: "Жаңалық қосу",
            save: "Сақтау",
            delete_news: "Жою",
            edit_news: "Өңдеу",
            look_news: "Толық көру",
            alert_delet: "Сіз бұл жазбаны жойғыңыз келе ме?",
        },

        ApplicantList: {
            list_applicant: "Ізденушілер тізімі",
            add_applicant: "Өтініш берушіні қосу",
            edit_applicant: "Өтініш берушіні өңдеу",
            history: "Cұхбат тарихы",
            oc: "Кезек",
            check: "Тексеру",
            vseCategory: "Барлық санаттар"
        },

        CompanyAdmins: {
            edit_admin: "Администраторды өңдеу",
            add_admin: "Администраторды қосу",
            admin_list: "Компаниядағы администраторлар тізімі",
            excel_export: "Excel файлға экспорттау",
            close_tab: "Жабу",
            downoload_file: 'Жазып алу',
            wait_until_load: 'Файлды жүктеп салу кезінде күтіңіз',
            finish_load: 'Файл жүктеп алуға дайын'
        },

        ApplicantView: {
            archive_applicant: "Іздеушіні мұрағатқа жіберу",
            cause: "Себебі",
            comments: "Пікір",
            rules: "Міндетті түрде толтырылуы тиіс",
            attach_file: "Файлды тіркеу",
            actions: "Әрекет жасалды",
            edit_request: "Өтінімнің мәртебесі өзгертті",
            image: "Суретті таңдаңыз",
            archive: "Мүрағатқа жіберу",
        },

        CenterLayoutView: {
            passages: "Жарияланған орындар",
            new: "Жаңа",
            vse: "Барлық жұмыс орындары",
            received: "Мақұлданған",
            published: "Жарияланған",
            completed: "Аяқталған",
            rejected: "Қабылданбаған",
            unemployed: "Жұмыс іздеуде",
            processing: "Өңделу барысында",
            interview: "Сұқбатқа шақырылғандар",
            employed: "Жұмысқа орналасқандар",
            archived: "Мұрағатталған",
            settings: "Баптаулар",
            company_lists: "Компаниялар тізімі",
            administrators: "Администраторлар",
            privileges: "Жеңілдіктер",
            positions: "Позициялар",
            ads: "Хабарландыру",
            profile: "Профиль",
            all: "Барлық өтінім тастаушылар",
            new_applicants: "Жаңа өтінімдер",
            accepted_applicants: "Қабылданды",
            rejected_applicants: "Қабылданбады"
        },

        CompanyView: {
            add_admin: "Администратор қосу",
            edit_admin: "Администраторды өңдеу",
            save: "Сақтау",
            statistics: "Статистика",
            applications_published: "Жарияланған өтінімдер",
            applications_completed: "Аяқталған өтінімдер",
            places_announced: "Жарияланған орын",
            hired: "Жұмысқа қабылданды",
            positions: "Позициялар",
            declared_places: "Жарияланған орындар",
            excel_export: "Excel файлға экспорттау",
            all_time: "Барлығы",
            per_week: "Апта ішінде",
            per_month: "Бір ай ішінде",
            per_year: "Бір жыл ішінде",
            all: "Барлығы",
        },

        CompanyList: {
            list_comnpany: "Компаниялар тізімі",
            add_company: "Жаңа компания қосу",
            edit_company: "Компанияны өздеу",
        },

        DashboardView: {
            vacancies: "Бос орындар",
            sent: "Жіберілген",
            published: "Жарияланған",
            finished: "Аяқталған",
        },

        ProfileView: {
            profile: "Профиль",
            enterprise_data: "Кәсіпорын деректері",
            bin: "БИН:",
            company_name: "Medianas",
            user_date: "Пайдаланушы деректері",
        },

        ReferenceList: {
            edit_company: "Компанияны өңдеу",
            add: "Қосу",
            save: "Сақтау",
            directory_not_found: "Анықтама табылмады",
            privileges: "Жеңілдіктер",
            positions: "Позициялар",
        },

        VacancyList: {
            vacancies: "Бос орындар",
            edit_company: "Компанияны өңдеу",
            add_free_space: "Бос орын қосу",
            save: "Сақтау",
            export_to_excel: "Excel бағдарламасына экспорттау",
            you_sure_delete: "Сіз бұл жазбаны шынымен жойғыңыз келе ме?",
        },

        VacancyView: {
            declared_place: "Жарияланған орын",
            submit_employment_center: "Жұмыспен қамту орталығына жіберу",
            sign_send: "Қол қою және жіберу",
            edit_app: "Өтінімді өңдеу",
            delete_app: "Өтінімді өшіру",
            accept_app: "Өтінімді қабылдау",
            accept: "Қабылдау",
            reject: "Қабылдамау",
            app: "өтінімді",
            reject_app: "Өтінімді қабылдамау",
            specify_reject_reason: "Бас тарту себебін көрсетіңіз",
            required_field: "Міндетті жол",
            value_short: "Мәні тым қысқа",
            sign_and: "Қол қою және",
            create_send_list_app:
                "Кандидаттардың тізімін қалыптастыру және жіберу",
            create: "Қалыптастыру",
            candidates: "кандидаттарды",
            add_beneficiaries: "Льготниктерді қосу",
            specify_quantity: "Санын көрсетіңіз",
            wrong_format: "Қате формат",
            value_high: "Мағынасы тым үлкен",
            add_vacancy: "Қосу",
            create_list_app: "Кандидаттардың тізімін қалыптастыру",
            send_list_app: "Кандидаттар тізімін жіберу",
            all: "Барлық",
            info_candidate: "Кандидат туралы ақпарат",
            changed_status_app: "Өтінімнің мәртебесін өзгертті ",
            comments: "Пікірлер",
            you_sure_delete: "Сіз бұл жазбаны шынымен жойғыңыз келе ме?",
        },

        CompanyAdminsView: {
            list_admin_company: "Компания әкімшілерінің тізімі",
            edit_admin: "Әкімшіні өңдеу",
            add_admin: "Әкімші қосу",
            save_company: "Сақтау",
            export_to_excel: "Excel бағдарламасына экспорттау",
        },
    },
    company: {
        AnnouncementList: {
            add_ads: "Хабарландыру қосу",
            edit_ads: "Жаңалықтарды өңдеу",
            add_news: "Жаңалықтар қосу",
            save_news: "Сақтау",
            delete_news: "Өшіру",
            edit_news: "Өңдеу",
            look_news: "Көру",
            you_sure_delete: "Сіз бұл жазбаны шынымен жойғыңыз келе ме?",
        },
        AnnouncementView: {
            ads: "Хабарландыру",
            add_ads: "Жаңалықтар қосу",
            delete_news: "Өшіру",
            look_news: "Көру",
        },
        ApplicantList: {
            recruited: "Жұмысқа қабылданғандар",
            interviewing: "Сұхбаттан өтеді",
            export_to_excel: "Excel бағдарламасына экспорттау",
        },
        CompanyLayoutView: {
            vacancies: "Бос орындар",
            sign_out: "Шығу",
            draft: "Шимай",
            sent: "Жіберілген",
            approved: "Мақұлданған",
            published: "Жарияланған",
            finished: "Аяқталған",
            declined: "Қабылданбаған",
            about_processing: "Өңделу барысында",
            interview_scheduled: "Сұқбат тағайындалды",
            employed: "Жұмысқа орналасқандар",
            settings: "Баптаулар",
            ads: "Хабарландыру",
            profile: "Профиль",
            in_kazakh: "Қазақша",
            in_rus: "Русский",
            new: "Жаңа өтінімдер"
        },
        DashboardView: {
            vacancies: "Бос орындар",
            sent: "Жіберілген",
            published: "Жарияланған",
            finished: "Аяқталған",
            pending_interview: "Сұхбатты күтушілер",
            kuanysh_fio: "Османов Қуаныш Рүстемұлы",
            profession: "Техник-технолог",
        },
        ProfileView: {
            profile: "Профиль",
            enterprise_data: "Данные предприятия",
            bin: "БИН:",
            company_name: "Medianas",
        },
        VacancyList: {
            add_free_space: "Бос орын қосыңыз",
            edit_company: "Компанияны өңдеу",
            save_company: "Сақтау",
            export_to_excel: "Excel бағдарламасына экспорттау",
            you_sure_delete: "Сіз бұл жазбаны шынымен жойғыңыз келе ме?",
        },
        VacancyView: {
            declared_place: "Жарияланған орын",
            submit_employment_center: "Жұмыспен қамту орталығына жіберу",
            sign_send: "Қол қою және жіберу",
            edit_app: "Өтінімді өңдеу",
            edit_company: "Компанияны өңдеу",
            add_free_space: "Бос орын қосыңыз",
            save_company: "Сақтау",
            delete_vacancy: "Өтінімді өшіру",
            all: " барлық",
            candidate_info: "Кандидат туралы ақпарат",
            changed_status_app: "Өтінімнің мәртебесін өзгертті ",
            comments: "Пікірлер",
            schedule_interview: "Сұхбатты тағайындау",
            reject: "Қабылдамау",
            recruit: "Жұмысқа қабылдау",
            interview_date: "Сұхбаттасу күні",
            required_field: "Міндетті жол",
            interview_time: "Сұхбаттасу уақыты",
            comments_address: "Пікір / мекен-жайы",
            value_short: "Мәні тым қысқа",
            send_vacancy: "Жіберу",
            order_date: "Бұйрық күні",
            order_number: "Бұйрықтың нөмірі",
            state_reason: "Себебін көрсетіңіз",
            you_sure_delete: "Сіз бұл жазбаны шынымен жойғыңыз келе ме?",
        },
    },
    download: "Жүктеу"
};
